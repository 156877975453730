import React from 'react';

import Menu from '../../components/Menu';

const ProjectPlaceholder = () => (
  <>
  <Menu />
  <div className="u-container u-center">
    <p>[ coming soon ]</p>
  </div>
  </>
);

export default ProjectPlaceholder;
